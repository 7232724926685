<template>
    <div class="ml-10 mr-10">
        <TitleBar v-if="this.$route.params.uuid === 'new'" :loading="loading">{{ $t("contact.new") }}</TitleBar>
        <TitleBar v-else
                  :disabled="loading"
                  :loading="loading"
                  :menu="[
                      {text: this.$t('contact.invoices'), callback: viewInvoices},
                      {text: this.$t('contact.bills'), callback: viewBills},
                      {text: this.$t('contact.delete'), callback: deleteElement, class: 'red--text'}]"
        >{{ $t("contact.edit") }}
        </TitleBar>

        <Tags v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Tags>

        <v-form @submit.prevent="formSubmit">
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        v-model="name"
                        :disabled="loading"
                        :error-messages="nameErrors"
                        :label="$t('contact.name')"
                        outlined
                        @blur="$v.name.$touch()"
                        @input="$v.name.$touch()"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="address_firstline"
                        :disabled="loading"
                        :error-messages="addressFirstLineErrors"
                        :label="$t('address.firstline')"
                        outlined
                        @blur="$v.address_firstline.$touch()"
                        @input="$v.address_firstline.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="address_secondline"
                        :disabled="loading"
                        :label="$t('address.secondline')"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="address_postcode"
                        :disabled="loading"
                        :error-messages="addressPostcodeErrors"
                        :label="$t('address.postcode')"
                        outlined
                        @blur="$v.address_postcode.$touch()"
                        @input="$v.address_postcode.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="address_city"
                        :disabled="loading"
                        :error-messages="addressCityErrors"
                        :label="$t('address.city')"
                        outlined
                        @blur="$v.address_city.$touch()"
                        @input="$v.address_city.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="address_country"
                        :disabled="loading"
                        :error-messages="addressCountryErrors"
                        :items="countries"
                        :label="$t('address.country')"
                        outlined
                        @blur="$v.address_country.$touch()"
                        @input="$v.address_country.$touch()"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="reference"
                        :disabled="loading"
                        :label="$t('contact.reference')"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="tax_number"
                        :disabled="loading"
                        :label="$t('contact.tax_number')"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="currency"
                        :disabled="loading"
                        :error-messages="currencyErrors"
                        :items="currencies"
                        :label="$t('contact.currency')"
                        outlined
                        @blur="$v.currency.$touch()"
                        @input="$v.currency.$touch()"
                    ></v-select>
                </v-col>
            </v-row>

            <v-divider class="mb-5"></v-divider>

            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="email"
                        :disabled="loading"
                        :error-messages="emailErrors"
                        :label="$t('contact.email')"
                        outlined
                        type="email"
                        @blur="$v.email.$touch()"
                        @input="$v.email.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="phone"
                        :disabled="loading"
                        :label="$t('contact.phone')"
                        outlined
                        type="tel"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="website"
                        :disabled="loading"
                        :error-messages="urlErrors"
                        :label="$t('contact.website')"
                        outlined
                        type="url"
                        @blur="$v.website.$touch()"
                        @input="$v.website.$touch()"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-btn
                :loading="loading"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $route.params.uuid === "new" ? $t("contact.add") : $t("contact.update") }}
            </v-btn>
        </v-form>


        <!--        Fields -->

        <Attachments v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Attachments>

        <!--        <v-btn-->
        <!--            elevation="2"-->
        <!--            color="error"-->
        <!--            :loading="loading"-->
        <!--            @click.prevent="deleteElement"-->
        <!--            class="mt-10"-->
        <!--            v-if="this.$route.params.uuid !== 'new'"-->
        <!--        >{{ $t("contact.delete") }}-->
        <!--        </v-btn>-->

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { email, required, url } from "vuelidate/lib/validators";
import Accounting from "../../helpers/Accounting";
import Tags from "../../components/Tags";
import Attachments from "../../components/Attachments";
import Countries from "../../helpers/Countries";
import Currencies from "../../helpers/Currencies";
import Utils from "../../helpers/Utils";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Details",
    components: {TitleBar, Attachments, Tags},
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.contacts"),
                to: "/contacts",
                exact: true
            },
            {
                text: this.$route.params.uuid === "new" ? this.$t("contact.new") : this.$t("contact.edit"),
                to: "/contacts/" + this.$route.params.uuid,
                exact: true
            }
        ]);

        this.countries = Countries.get(this.$i18n.locale);

        if (this.$route.params.uuid !== "new") {
            this.loading = true;
            Accounting.get("/contacts/" + this.$route.params.uuid)
                .then(response => {
                    const data = response.data;
                    this.name = data.name;
                    this.address_firstline = data.address.firstline;
                    this.address_secondline = data.address.secondline;
                    this.address_city = data.address.city;
                    this.address_postcode = data.address.postcode;
                    this.address_country = data.address.country;
                    this.currency = data.currency;
                    this.email = data.email;
                    this.website = data.website;
                    this.phone = data.phone;
                    this.tax_number = data.tax_number;
                    this.reference = data.reference;
                    this.loading = false;
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        }


    },
    data: () => {
        return {
            loading: false,
            name: null,
            address_firstline: null,
            address_secondline: null,
            address_postcode: null,
            address_city: null,
            address_country: null,
            reference: null,
            tax_number: null,
            currency: null,
            email: null,
            phone: null,
            website: null,
            countries: [],
            currencies: Currencies.get()
        };
    },
    methods: {
        viewInvoices() {
            this.$router.push({
                path: "/incomes/invoices",
                query: {
                    contact: this.$route.params.uuid
                }
            });
        },
        viewBills() {
            this.$router.push({
                path: "/expenses/bills",
                query: {
                    contact: this.$route.params.uuid
                }
            });
        },
        deleteElement() {
            this.$swal({
                title: this.$t("contact.delete_title"),
                text: this.$t("contact.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("contact.delete_yes"),
                cancelButtonText: this.$t("contact.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/contacts/" + this.$route.params.uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("contact.deleted")
                            }]);
                            this.loading = false;
                            this.$router.push("/contacts");
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                let url = "/contacts";

                if (this.$route.params.uuid !== "new") {
                    url = url + "/" + this.$route.params.uuid;
                }

                this.$store.commit("SET_ALERTS", []);

                Accounting.post(url, {
                    address_city: this.address_city,
                    address_country: this.address_country,
                    address_firstline: this.address_firstline,
                    address_postcode: this.address_postcode,
                    address_secondline: this.address_secondline,
                    currency: this.currency,
                    email: this.email,
                    name: this.name,
                    phone: this.phone,
                    reference: this.reference,
                    tax_number: this.tax_number,
                    website: this.website
                }).then(response => {

                    this.loading = false;
                    if (this.$route.params.uuid === "new") {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("contact.added")
                        }]);
                        this.$router.push("/contacts/" + response.data.uuid);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("contact.updated")
                        }]);
                    }

                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    },
    mixins: [validationMixin],
    validations: {
        email: {email},
        website: {url},
        name: {required},
        address_firstline: {required},
        address_postcode: {required},
        address_city: {required},
        address_country: {required},
        currency: {required}
    },
    computed: {
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push(this.$t("contact.email_valid"));
            return errors;
        },
        urlErrors() {
            const errors = [];
            if (!this.$v.website.$dirty) return errors;
            !this.$v.website.url && errors.push(this.$t("contact.url_valid"));
            return errors;
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.required && errors.push(this.$t("contact.name_required"));
            return errors;
        },
        addressFirstLineErrors() {
            const errors = [];
            if (!this.$v.address_firstline.$dirty) return errors;
            !this.$v.address_firstline.required && errors.push(this.$t("contact.firstline_required"));
            return errors;
        },
        addressPostcodeErrors() {
            const errors = [];
            if (!this.$v.address_postcode.$dirty) return errors;
            !this.$v.address_postcode.required && errors.push(this.$t("contact.postcode_required"));
            return errors;
        },
        addressCityErrors() {
            const errors = [];
            if (!this.$v.address_city.$dirty) return errors;
            !this.$v.address_city.required && errors.push(this.$t("contact.city_required"));
            return errors;
        },
        addressCountryErrors() {
            const errors = [];
            if (!this.$v.address_country.$dirty) return errors;
            !this.$v.address_country.required && errors.push(this.$t("contact.country_required"));
            return errors;
        },
        currencyErrors() {
            const errors = [];
            if (!this.$v.currency.$dirty) return errors;
            !this.$v.currency.required && errors.push(this.$t("contact.currency_required"));
            return errors;
        }
    }
};
</script>

<style scoped>

</style>